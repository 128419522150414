import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './plugins/element.js'
import 'element-ui/lib/theme-chalk/index.css';
//导入全局样式表
import './assets/css/global.css'

import axios from 'axios'
Vue.prototype.axios = axios;
Vue.prototype.$http = axios;
// Vue.prototype.$http=axios 
axios.defaults.timeout = 3500 // 请求超时
// axios.defaults.baseURL = 'https://api.tkcraft.cn/project/student/'  // api 即上面 vue.config.js 中配置的地址
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
axios.interceptors.request.use(config => {
  console.log(config)
  config.headers.Authorization = window.sessionStorage.getItem('token')
  // request.headers.set('token', token); //setting request.headers
  //在最后必须return config
  return config
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
