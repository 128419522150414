import Vue from 'vue'
import { 
    Button,
    Form,FormItem,
    Input,
    Container,
    Header,
    Aside,
    Main,
    Menu,
    MenuItem,
    MenuItemGroup,
    Submenu,
    Breadcrumb,
    BreadcrumbItem,
    Card,
    Row,
    Col,
    Footer,
    Link,
    Divider,
    Upload,
    Carousel,
    Pagination,
    Backtop,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Tabs,
    TabPane,
    Image
} from 'element-ui'
import {Message} from 'element-ui'//导入弹框提示组件

Vue.use(Footer)

Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Link)
Vue.use(Divider)
Vue.use(Upload)
Vue.use(Divider)
Vue.use(Carousel)
Vue.use(Pagination)
Vue.use(Backtop)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Image)
Vue.use(Backtop)

Vue.prototype.$message = Message//挂载