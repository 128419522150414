<template>
  <div class="page">
    <el-container>
      <el-header>
        <h2>TServer Bing</h2>
        <h4>必应壁纸</h4>
      </el-header>
      <el-main>
        <div class="container">
          <div
            class="each"
            v-for="(item, index) in imgurl"
            :id="'each' + index"
          >
            <img
              class="img-not-load"
              :src="'http://' + item.imgurl"
              alt=""
              :key="index"
              :id="'img' + index"
            />
            <a href=""></a>
            <div class="description">
              <h3 class="title">{{ item.imgcopyright }}</h3>
              <h4>{{ item.imgdate }}</h4>
            </div>
          </div>
        </div>
        <div class="pagination">
          <span class="demonstration">完整功能</span>
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            background
            layout="total, prev, pager, next, jumper"
            :total="imgtotal"
          >
          </el-pagination>
        </div>
      </el-main>
      <el-footer>
        <h4>
          本站点所有图片源自
          <a href="https://cn.bing.com/" target="_blank">必应搜索</a>
        </h4>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgurl: [],
      currentPage: 1,
      imgtotal: 20,
    };
  },
  created() {
    this.pagenum();
  },
  mounted() {
    this.gotopage(this.currentPage);
    window.addEventListener("scroll", this.windowScroll);
    // document.oncontextmenu = function () {
    //   return false;
    // };
  },
  methods: {
    async gotopage(page) {
      const { data: res } = await this.$http.get(
        "https://api.tkcraft.cn/img/bing/gotopage.php?page=" + page
      );
      if (res.code == 200) {
        this.imgurl = res.data;
      }
    },
    async pagenum() {
      const { data: res } = await this.$http.get(
        "https://api.tkcraft.cn/img/bing/pagenum.php?"
      );
      if (res.code == 200) {
        this.imgtotal = (res.data.num)*1;
        // this.$message.success(this.imgtotal);
      }
    },
    windowScroll() {
      var imgs = document.querySelectorAll("img");
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      let clienHeight = document.documentElement.clientHeight; //可视区域高度

      for (var i = 0; i < imgs.length; i++) {
        if (scrollTop + clienHeight - 100 > imgs[i].offsetTop) {
          //   imgs[i].src = imgs[i].getAttribute("data-src");
          //   document.getElementById("img" + i).style.filter = "blur(0px)"; //可以修改filter
          document.getElementById("img" + i).className = "img-load";
        }
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.gotopage(val);
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.windowScroll);
  },
};
</script>
<style lang="less" scoped>
.description {
  width: 30%;
  color: white;
  position: absolute;
  h3 {
    font-size: 0.12rem;
    font-weight: 550;
    text-indent: 0.5rem;
    position: relative;
    bottom: 19rem;
    left: 0.5rem;
  }
  h4 {
    font-size: 0.12rem;
    font-weight: 550;
    text-indent: 0.5rem;
    position: relative;
    bottom: 19rem;
    left: 0.5rem;
  }
}
.each {
  //   position: relative;
  display: inline-block;
  width: 33.3%;
  margin: 0;
  padding: 0;
  height: 20rem;
}
.page {
  .el-header {
    text-align: center;
    height: 400px;
    // min-height: 200px;
    h2 {
      display: inline-block;
    }
    h4 {
      display: inline-block;
    }
  }
  .el-footer {
    color: #16cfea;
    text-decoration: none;
    text-align: center;
    margin-top: 120px;
  }
  .el-main {
    font-size: 0;
    background-color: #e9eef3;
    padding: 0;

    //   line-height: 160px;
  }
}
.img-not-load {
  width: 100%;
  height: 100%;
  //   border-left: 1px solid rgba(255, 255, 255, 0.2);
  //   filter: url(); /* FireFox, Chrome, Opera */
  //   background-position: center;
  //   background-size: cover;

  // animation: sharpen 0.5s both;
  //   animation: 0s;
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=10, MakeShadow=false); /* IE6~IE9 */
  filter: blur(20px);
  // height: auto;
}
.img-load {
  width: 100%;
  height: 100%;
  //   filter: blur(20px);
  animation: sharpen 0.7s both;
}
.pagination {
  position: relative;
  text-align: center;
  // top: 4rem;
  // position: absolute;
  // top: 4rem;
  height: 4rem;
  // transform: translate(0,2rem);
}
@keyframes sharpen {
  0% {
    filter: blur(20px);
  }
  100% {
    filter: blur(0px);
  }
}
</style>